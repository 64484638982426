<template>
  <v-container fluid fill-height>
    <v-row no-gutters  align="center" justify="center">
      <v-col cols="12" class="py-0" xs="12" sm="8" md="4">
        <v-form ref="securityForm" v-model="validSecurity">
          <v-card elevation="6">
            <v-toolbar flat>
              <v-toolbar-title class="subtitle-1">Зміна пароля після його скидання</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mb-2"/>
            <v-card-text class="py-1 px-3">
              <v-row class="pt-4" :class="`${$vuetify.breakpoint.smAndUp ? 'px-4' : ''}`">
                <v-col cols="12" class="py-0" md="12" xs="12">
                  <v-text-field
                    id="password"
                    label="Новий пароль"
                    name="password"
                    prepend-icon=""
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    :rules="[rules.required, rules.valueLength(password, 'Пароль', 6)]"
                    validate-on-blur
                    @click:append="showPassword = !showPassword"
                    outlined
                    tabindex="1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="py-0" md="12" xs="12">
                  <v-text-field
                    id="confirmPassword"
                    label="Новий пароль (ще раз)"
                    name="confirmPassword"
                    prepend-icon=""
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    v-model="confirmPassword"
                    :rules="[rules.required, rules.equal(confirmPassword, 'Паролі не співпадають', password)]"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    outlined
                    tabindex="2"
                  />
                </v-col>
                <v-col cols="12" md="12" xs="12" class="py-0 text-md-end text-xs-end mb-3">
                  <v-btn class="success" :disabled="!validSecurity" @click="changePassword">Змінити пароль</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UserAPI from '@/api/user'
  import {VALIDATION_RULES} from '@/utils'

  export default {
    name: 'ResetPasswordMessage',
    data() {
      return {
        password: '',
        showPassword: false,
        confirmPassword: '',
        validSecurity: false,
        showConfirmPassword: false,
      }
    },
    methods: {
      async changePasswordAPI() {
          try {
              let payload = {code: this.$route.query.code, password: this.password};
              await UserAPI.resetPassword(payload);
          } catch (e) {
            this.$snackbar("Помилка при зміні паролю", 'error')
          }
      },
      changePassword() {
        if (this.$refs.securityForm.validate() && this.validSecurity) {
            this.changePasswordAPI()
                .then(() => {
                    this.$router.push({name: 'login'});
                    this.$snackbar("Авторизуйтесь уже з новим паролем", 'success');
                })
                .catch(() => {
                    this.$snackbar("Пароль не змінено. Спробуйте ще раз", 'error');
                });
        }
      }
    },
    computed: {
      rules() {
          return VALIDATION_RULES;
      },
    }
  }
</script>

<style scoped lang="scss">
</style>
